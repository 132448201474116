import { getTicketDetail } from "@/api/index.js";

export default {
  async created() {
    if (this.$route.query.activityId) {
      await this.getTicketDetail();
    }
  },
  methods: {
    async getTicketDetail() {
      await getTicketDetail(this.$route.query.activityId).then(res => {
        this.activity = res;
        this.callback && this.callback();
      });
    }
  }
};
