<template>
  <div v-loading="isLoading">
    <div class="buy-ticket-wrapper">
      <el-button
        type="text"
        class="back-button"
        icon="el-icon-arrow-left"
        @click="$router.go(-1)"
        style="margin-left: -10px;margin-top: -5px;"
      ></el-button>
      <!-- <h3 class="page-title">购票信息</h3> -->
      <el-form ref="form" :rules="rules" :model="formData">
        <div>
          <el-form-item prop="name" label="姓名">
            <el-input
              v-model="formData.name"
              data-field="name"
              class="weui-input"
              placeholder="请输入购票者或参与者的姓名"
            />
          </el-form-item>

          <el-form-item prop="mobile" label="联系电话">
            <el-input
              v-model="formData.mobile"
              data-field="mobile"
              class="weui-input"
              placeholder="请输入购票者或参会者的手机号，用于活动通知或紧急联系"
            />
          </el-form-item>

          <el-form-item prop="email" label="邮箱">
            <el-input
              v-model="formData.email"
              data-field="email"
              class="weui-input"
              placeholder="请输入电子邮箱，用于活动通知"
            />
          </el-form-item>

          <el-form-item prop="companyName" label="公司名称">
            <el-input
              v-model="formData.companyName"
              data-field="companyName"
              class="weui-input"
              placeholder="请输入公司名称"
            />
          </el-form-item>

          <el-form-item prop="address" label="联系地址">
            <el-input
              v-model="formData.address"
              data-field="address"
              class="weui-input"
              placeholder="请输入联系地址，用于门票等实物邮寄"
            />
          </el-form-item>

          <el-form-item prop="vote" label="" v-if="activity.enableSeat">
            <div class="seat-section-wrap">
              <el-button
                class="seat-selection"
                type="success"
                size="mini"
                @click="seatSelectionHandle"
              >
                选择座位
              </el-button>
              <div class="ticket-tips">{{ ticketTips }}</div>
            </div>
            <div class="check-seat-scroll-wrap">
              <div
                class="seat-ticket"
                v-for="(item, i) in seats"
                :key="i"
                @click="seats.splice(i, 1)"
              >
                <div class="title">
                  <span>{{ item.area }} {{ item.row }}排{{ item.col }}座</span>
                </div>
                <div class="price-v">￥{{ item.price }}</div>
                <i class="el-icon-close"></i>
              </div>
            </div>
          </el-form-item>

          <el-form-item v-else prop="vote" class="vote-form-item" label="票数">
            <div class="vote-wrap">
              <span class="icon_ min-icon" @click="minusHandle"></span>
              <el-input readonly v-model="formData.vote" class="num"></el-input>
              <span class="icon_ add-icon" @click="addHandle"></span>
            </div>
          </el-form-item>
          <div>
            <!-- <div class="tickets-left">余票：{{ stock || 0 }} 张</div> -->
            <div class="price">
              ￥{{ totalPrice }}
              <span>￥{{ originPrice }}</span>
            </div>
          </div>
        </div>

        <div @click="submitForm" class="submit-button">
          <div>
            <span>提交</span>
          </div>
        </div>
      </el-form>
    </div>
    <router-view
      @resove-url="copyUrl = $event"
      @resolve-seat="seats = $event"
      :seats="seats"
    />
  </div>
</template>

<script>
import Mixin from "./mixin.js";
import {
  createOrder,
  buyOrderUrl,
  getOrderDetail,
  getWxUlr
} from "@/api/index.js";

export default {
  name: "BuyTicket",
  mixins: [Mixin],
  data() {
    return {
      isLoading: false,
      copyUrl: "",
      status: "init", // 订单状态，init: 初始化， submit： 已提交, copied: 已复制支付链接
      order: {}, // 订单
      customerName: "",
      formData: {
        name: "",
        mobile: "",
        email: "",
        companyName: "",
        address: "",
        vote: 0
      },
      price: 0,
      stock: 9999,
      id: "",
      discountCondition: 0,
      discount: 10,
      ticketCategory: "",
      rules: {
        name: [
          { required: true, message: "姓名必填", trigger: "blur" },
          { max: 100, message: "姓名不能超过100个字", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "联系电话必填", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur"
          }
        ],
        email: [
          {
            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            message: "请输入有效的电子邮箱",
            trigger: "blur"
          }
        ],
        companyName: [
          { required: true, message: "公司名称必填", trigger: "blur" },
          { max: 50, message: "公司名称不能超过50个字", trigger: "blur" }
        ],
        address: [
          { max: 100, message: "地址不能超过100个字", trigger: "blur" }
        ],
        vote: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.seats.length > 0 || value > 0) {
                callback();
              } else {
                callback(new Error("请至少添加一张票"));
              }
            }
          }
        ]
      },
      seats: [],
      activity: {}
    };
  },
  watch: {
    seats(val) {
      this.formData.vote = val.length;
      this.$refs.form.validateField("vote");
    }
  },
  computed: {
    originPrice() {
      return this.formData.vote * this.activity.price;
    },
    totalPrice() {
      if (this.activity.enableSeat) {
        let total = 0;
        this.seats.forEach(v => {
          if (this.seats.length >= this.discountCondition) {
            total += v.price * (this.discount / 10) * 10000;
          } else {
            total += v.price * 10000;
          }
        });
        return (total / 10000).toFixed(2);
      } else {
        let price = this.formData.vote * this.activity.price;
        if (this.formData.vote >= this.discountCondition) {
          price = price * (this.discount / 10);
        }
        return price.toFixed(2);
      }
    }
    // activity() {
    //   return this.$store.state.normal.activity || {};
    // }
  },
  created() {
    this.discountCondition = this.$route.query.discountCondition;
    this.discount = this.$route.query.discount;
    this.ticketTips = this.$route.query.ticketTips;
    this.ticketCategory = this.$route.query.ticketCategory;
    this.customerName = localStorage.getItem("customerName");
    this.formData.name = this.customerName || "";
  },
  methods: {
    minusHandle() {
      let number = --this.formData.vote;
      this.formData.vote = number < 0 ? 0 : number;

      this.calcPrice();
    },
    addHandle() {
      let number = ++this.formData.vote;

      this.formData.vote = number > this.stock ? this.stock : number;

      this.calcPrice();
    },
    calcPrice() {
      let price = this.formData.vote * this.activity.price;
      if (this.formData.vote >= this.discountCondition) {
        price = price * (this.discount / 10);
      }

      this.price = price.toFixed(2);
    },
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.isLoading = true;
          createOrder({
            company: this.formData.companyName,
            email: this.formData.email,
            customerName: this.formData.name,
            idNumber: this.formData.idCard,
            mobile: this.formData.mobile,
            address: this.formData.address,
            ticketCount: this.formData.vote,
            ticketId: this.activity.id,
            ticketSeatIds: this.seats.length
              ? this.seats.map(v => v.seatId)
              : null,
            totalPrice: this.price
          })
            .then(res => {
              this.isLoading = false;

              if (res) {
                this.order = res;
                this.status = "submit";

                this.applyWxPay();

                this.$confirm("请选择支付方式", "支付提示", {
                  confirmButtonText: "支付宝支付",
                  cancelButtonText: "微信支付",
                  distinguishCancelAndClose: true,
                  confirmButtonClass: "alipay-btn",
                  cancelButtonClass: "el-button--success",
                  type: "info",
                  center: true,
                  callback: type => {
                    if (type === "confirm") {
                      if (this.isWx()) {
                        this.$router.push({
                          name: "Alipay",
                          query: {
                            orderId: this.order.id
                          }
                        });
                      } else {
                        window.open(buyOrderUrl(this.order.id));
                      }
                    } else if (type === "cancel") {
                      this.wxPay();
                    }
                  }
                });
              }
            })
            .catch(({ response }) => {
              if (response.data.code) {
                let error = {
                  100001: "门票不存在！",
                  100002: "门票已售完",
                  100003: "已结束售票"
                };
                this.$message.error(
                  error[response.data.code] || response.data.message
                );
              }
            });
        }
      });
    },

    seatSelectionHandle() {
      this.$router.push({
        name: "SeatSelection",
        query: this.$route.query
      });
    },

    isWx() {
      //判断是否为微信
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.includes("micromessenger")) {
        return true;
      }
      return false;
    },
    /**
     * 复制支付链接
     */
    onCopyPayUrl() {
      const input = document.createElement("input"); // 创建一个新input标签
      input.setAttribute("readonly", "readonly"); // 设置input标签只读属性
      input.setAttribute("value", this.copyUrl); // 设置input value值为需要复制的内容
      document.body.appendChild(input); // 添加input标签到页面
      input.select(); // 选中input内容
      input.setSelectionRange(0, 9999); // 设置选中input内容范围
      document.execCommand("copy"); // 复制
      document.body.removeChild(input); // 删除新创建的input标签

      this.$message({
        type: "success",
        message: "链接复制成功"
      });
      this.status = "copied";
    },
    applyWxPay() {
      var fp = new window.Fingerprint2();
      fp.get(result => {
        window.$.getJSON(getWxUlr(this.order.id, result), d => {
          if (d.appId) {
            this.appId = d.appId;
            this.nonceStr = d.nonceStr;
            this.packageValue = d.packageValue;
            this.paySign = d.paySign;
            this.signType = d.signType;
            this.timeStamp = d.timeStamp;
          } else {
            this.$message.warning(d.message);
          }
        });
      });
    },
    onBridgeReady() {
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: this.appId, //公众号ID，由商户传入
          timeStamp: this.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: this.nonceStr, //随机串
          package: this.packageValue,
          signType: this.signType, //微信签名方式：
          paySign: this.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            window.location.href = "/mine";
          } else {
            alert("支付失败！");
          }
        }
      );
    },
    wxPay() {
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    /**
     * 已完成支付
     */
    onOrderPaied() {
      getOrderDetail(this.order.id).then(res => {
        if (res && res.status === "PAID") {
          this.$confirm("已完成支付，请到我的订单中查看详情", "支付状态", {
            confirmButtonText: "确定",
            type: "info",
            showCancelButton: false,
            center: true
          }).then(() => {
            this.$router.push({
              name: "MineDetail",
              params: {
                id: this.order.id
              }
            });
          });
        } else {
          this.$alert(
            "支付未完成，如果已支付，请稍后到我的订单中查看状态信息",
            "支付状态",
            {
              confirmButtonText: "确定",
              type: "info",
              center: true
            }
          );
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>

.buy-ticket-wrapper {
  padding: 10px 15px 180px 15px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  min-height: calc(100vh - 40px);
  box-sizing: border-box;
  /deep/ .el-form-item__label {
    font-size: 14px;
  }
  .page-title {
    font-size: 20px;
    text-align: center;
    padding: 10px 0 20px;
  }
}

.half-wrap{
  display: flex;
  .submit-button {
    width: 50%;
    &:last-child {
      left: 50%;
      div {
        background-image: linear-gradient(90deg, #67c23a, #67c23a);
        box-shadow: 5px 5px 10px rgba(103, 194, 58, .4);
      }
    }
  }
}

.vote-wrap {
  display: flex;
  float: right;
  width: 70%;
}

.vote-wrap .icon_ {
  // font-size: 30px;
  padding: 0 10px;
}

.buy-ticket-wrapper .vote-wrap span {
  width: calc(100% / 3);
  text-align: center;
}

.vote-wrap .min-icon {
  // font-size: 24px;
  transform: scale(1, .7);
}

.vote-wrap .add-icon {
  position: relative;
}

.vote-wrap .add-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transform: rotate(-90deg);
}

.vote-wrap .min-icon::before,
.vote-wrap .add-icon::after,
.vote-wrap .add-icon::before {
  content: '';
  display: block;
  width: 15px;
  height: 3px;
  background-color: #000000;
  margin: 18px auto;
}

.price {
  font-size: 20px;
  line-height 20px;
  color: #ff8e38;
  float: right;
}
.price span{
  font-size: 12px;
  color: #cccccc;
  text-decoration: line-through;
}


.tickets-left {
  font-size: 16px;
  line-height 20px;
  color: #333333;
  float: left;
}
.vote-form-item {
  /deep/ .el-form-item__label {
    margin-top: 8px;
  }
}
.check-seat-scroll-wrap {
  max-height: 300px;
  overflow: auto;
  // width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.seat-section-wrap {
  margin-bottom: 10px;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  .ticket-tips {
    color: #999999;
    font-size: 12px;
    float: right;
    margin-right: 5px;
    line-height: 28px;
  }
  .seat-selection {
    float: right;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #57b837;
  }
}

.seat-ticket {
  margin-bottom: 5px;
  min-width: calc(50% - 38px);
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4px 25px 4px 8px;
  line-height: 1.3em;
  .title {
    font-size: 14px;
    color: #333333;
  }
  .price-v {
    margin-top: 2px;
    font-size: 14px;
    color: #ff8938;
    font-weight: bold;
  }
  .el-icon-close {
    position: absolute;
    font-size: 18px;
    right: 5px;
    top: calc(50% - 9px);
    color: #d3d5d8;
  }
}
</style>

<style lang="stylus">
.wxpay-btn {
  background-color: #24af41;
}
</style>
